import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet} from "react-router-dom";
import { getFormatedUserData } from "../../helpers/dataFormating";
import { getLandingPageData, getUser,setUser } from "../../helpers/services";
import { getIsAuth, getPersonalDataLoaded, getUserData } from "../../redux/userSelectors";
import { actions } from "../../redux/userSlice";
import Preloader from "../Preloader";


export const WithPersonalData = () => {
    const isAuth = useSelector(getIsAuth)
    const { customerUuid } = useSelector(getUserData)
    const personalDataLoaded = useSelector(getPersonalDataLoaded)
    const [isLoading, setIsLoading] = useState(true);
    const dispatch = useDispatch()

    const updateDeadlineValue = (customerData) => {
      const days = 14;
      if(!customerData.deadline){
       const deadlineDate = new Date( Date.now() + days * 24 * 60 * 60 * 1000)
       let deadlineMonth = deadlineDate.getMonth() + 1;
       let deadlineDay = deadlineDate.getDate();
       if (deadlineDay < 10) deadlineDay = '0' + deadlineDay
       if (deadlineMonth < 10) deadlineMonth = '0' + deadlineMonth

       setUser({
        deadline: `${deadlineDay}/${deadlineMonth}`,
        deadline_day: `${deadlineDay}`,
        deadline_month: `${deadlineMonth}`
        })
      }

      //console.log('customerData.groups')
      //console.log(customerData.groups)
      let add_group = [customerData.platform_user+'_logged_in'];
      let updated_groups = [...customerData.groups, ...add_group.filter(c => !customerData.groups.includes(c)) ];
      console.log(updated_groups)

      setUser({
        groups: updated_groups
      })
      dispatch(
        actions.setUserData({
          groups: updated_groups
        }),
      )
    }

    const handleInitialDataLoad = useCallback(async () => {
      
          const {data:userData} = await getUser(customerUuid)
          const {data:landingData} = await getLandingPageData()
          updateDeadlineValue(landingData.customer)
          dispatch(actions.setUserData(getFormatedUserData(userData, landingData.customer)))
          dispatch(actions.setContentData({...landingData.personalized_data, updated_at: landingData.updated_at}));
          dispatch(
            actions.setUserData({
              check_finished: landingData.customer.check_finished,
              precheck_finished: landingData.customer.precheck_finished,
            })
          );
      },[customerUuid, dispatch])
    
    
    useEffect(() => {
      if(isAuth){
        setIsLoading(true)
        handleInitialDataLoad()
        .then(() => {
          dispatch(actions.setPersonalDataLoaded(true))
          setIsLoading(false)
        })
        .catch((error) => {
          console.warn(error)
          setIsLoading(false)
        })
      }
    
      }, [isAuth, dispatch, handleInitialDataLoad])


    return personalDataLoaded  ? <Outlet /> : isLoading   ? <Preloader/> : <Outlet />
    
            
    
    
}

